type TextHighlightProps = {
  text?: string;
  highlight?: string | Array<string>;
  className?: string;
};

const placeholderBeforeMatch = '\uFEFD';
const htmlBeforeMatch = '<span class="text-highlight">';
const placeholderAfterMatch = '\uFEFE';
const htmlAfterMatch = '</span>';

const regexBefore = new RegExp(escapeRegExp(placeholderBeforeMatch), 'gi');
const regexAfter = new RegExp(escapeRegExp(placeholderAfterMatch), 'gi');

export function TextHighlight(props: TextHighlightProps): JSX.Element {
  const highlights = Array.isArray(props.highlight) ? props.highlight : [props.highlight];

  const textWithHtmlPlaceholders = highlights.reduce((accumulatedText, highlightText) => {
    return highlight(accumulatedText, highlightText);
  }, props.text);

  const html = textWithHtmlPlaceholders?.replace(regexBefore, htmlBeforeMatch).replace(regexAfter, htmlAfterMatch);

  return <span className={props.className} dangerouslySetInnerHTML={{ __html: html ?? '' }}></span>;
}

export function highlight(text?: string, phrase?: string): string {
  if (text == null || phrase == null) {
    return text ?? '';
  }

  const pattern = escapeRegExp(phrase);
  const regex = new RegExp(pattern, 'gi');

  return text.replace(regex, (match: string): string => `${placeholderBeforeMatch}${match}${placeholderAfterMatch}`);
}

function escapeRegExp(text: string): string {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
