import { PortalConfiguration } from '@atlas-engine-contrib/atlas-ui_contracts';

import { Portal } from '.';
import type { LanguageService } from './LanguageService';

export class ExtensionService {
  private portal: Portal;

  constructor(portalConfiguration: PortalConfiguration, languageService: LanguageService) {
    this.portal = new Portal(portalConfiguration, languageService);
  }

  public async loadExtensions(): Promise<void> {
    // "window.__getPortalExtensions" is defined in extensions.js (external script served by backend)

    if (window.__getPortalExtensions == null) {
      console.warn('Cannot load extensions: `window.__getPortalExtensions` is not defined');
      return;
    }

    const extensionsModules = window.__getPortalExtensions();

    for (const extensionModule of extensionsModules) {
      if (extensionModule.exports.onLoad) {
        try {
          await extensionModule.exports.onLoad(this.portal);
        } catch (error) {
          console.warn(`Could not load extension: ${extensionModule.name}`);
          console.error(error);
        }
      } else {
        console.warn(`Expected extension to export an \`onLoad\` hook: ${extensionModule.name}`);
      }
    }
  }

  public getPortal(): Portal {
    return this.portal;
  }
}
