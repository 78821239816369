import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import { StartDialogsConfig } from '@atlas-engine-contrib/atlas-ui_contracts';

import { EngineService } from '../../../lib';
import { TranslatedTaskList } from '../../components/task-list/TaskList';
import { Layout, LayoutContent, LayoutHeader, LayoutSidebar } from '../../Layout';
import { GenericViewProps } from '../../GenericViewProps';

export type TaskListViewProps = {
  engineService: EngineService;
  startDialogsConfig: StartDialogsConfig;
} & RouteComponentProps &
  WithTranslation &
  GenericViewProps;

export type TaskListViewState = {
  searchFilter: string;
};

class TaskListView extends Component<TaskListViewProps, TaskListViewState> {
  public state = {
    searchFilter: '',
  };

  public render(): JSX.Element {
    const { t } = this.props;

    const onSearchChanged = (value: string): void => {
      this.setState({ searchFilter: value });
    };

    return (
      <Layout>
        <LayoutSidebar
          activeNav="task-list"
          visible={this.props.sidebarVisible}
          hideSidebar={this.props.hideSidebar}
          logo={this.props.logo}
        />
        <LayoutHeader
          title={t('Header.TitleTaskList')}
          showSearch={true}
          onSearchChanged={onSearchChanged}
          onMenuClick={this.props.onMenuClick}
        />
        <LayoutContent>
          <div className="task-list-view">
            <div className="task-list-view__task-list">
              <TranslatedTaskList
                engineService={this.props.engineService}
                searchFilter={this.state.searchFilter}
                showTitle={false}
              />
            </div>
          </div>
        </LayoutContent>
      </Layout>
    );
  }
}

export const TaskListViewWithRouter = withTranslation()(withRouter(TaskListView));
