import { AuthorityConfig } from '@atlas-engine-contrib/atlas-ui_contracts';
import { OnIdentityChangedCallback } from '.';
import { IAuthService, IdentityWithEmail, ProcessSigninResponseResult } from './IAuthService';

const dummyIdentity = {
  userId: 'demo@5minds.de',
  email: 'demo@5minds.de',
  token: 'ZHVtbXlfdG9rZW4=',
};

export class AuthServiceDummy implements IAuthService {
  public login(targetRoutingState?: unknown): Promise<void> {
    return Promise.resolve();
  }

  public logout(): void {}

  public getGrantType(): AuthorityConfig['grantType'] {
    return 'implicit';
  }

  public setSessionId(sessionId: string): void {}

  public processSigninResponse(): Promise<ProcessSigninResponseResult> {
    return Promise.resolve({
      identity: dummyIdentity,
      targetRoute: '/',
    });
  }

  public processSignoutResponse(): Promise<void> {
    return Promise.resolve();
  }

  public detectLoggedInFlag(): Promise<boolean> {
    return Promise.resolve(true);
  }

  public getIdentity(): Promise<IdentityWithEmail> {
    return Promise.resolve(dummyIdentity);
  }

  public isLoggedIn(): boolean {
    return true;
  }

  public hasClaim(claim: string): Promise<boolean> {
    return Promise.resolve(true);
  }

  public onIdentityChanged(callback: OnIdentityChangedCallback): void {}

  public removeOnIdentityChanged(callback: OnIdentityChangedCallback): void {}
}
