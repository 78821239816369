import { Component, createRef, Fragment } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import {
  IFormStatePersistenceService,
  ManualTaskAbortedEvent,
  ManualTaskFinishedEvent,
  ManualTaskSuspendedEvent,
  ProcessInstanceTerminateEvent,
  ProcessInstanceTerminatedEvent,
  TaskViewConfig,
  UserTaskAbortedEvent,
  UserTaskFinishedEvent,
  UserTaskSuspendedEvent,
  CustomFormTranscendentErrorEvent,
} from '@atlas-engine-contrib/atlas-ui_contracts';
import { UserInteractionComponent } from '@atlas-engine-contrib/atlas-ui_user-interaction-component';
import { BpmnType } from '@atlas-engine/atlas_engine_sdk';

import { AnyTaskType, EngineService, IdentityWithEmail } from '../../../lib/index';
import { ErrorRenderer } from '../../components/ErrorRenderer';

import { WithIdentity, withIdentity } from '../../context';
import type { LanguageService } from '../../../lib/LanguageService';

type TaskViewState = {
  error?: Error;
  showConfirmTerminationModal: boolean;
  taskIsFinished: boolean;
};

type TaskViewProps = {
  task?: AnyTaskType;
  engineService: EngineService;
  taskViewConfig: TaskViewConfig;
  componentStatePersistenceService: IFormStatePersistenceService;
  languageService: LanguageService;
  onReady: () => void;
  onTaskFinished: (event: ManualTaskFinishedEvent | UserTaskFinishedEvent, task: AnyTaskType) => void;
  onTaskSuspended: (
    event: ManualTaskSuspendedEvent | UserTaskSuspendedEvent | ManualTaskAbortedEvent | UserTaskAbortedEvent,
    task: AnyTaskType
  ) => void;
  onProcessInstanceTerminated: (event: ProcessInstanceTerminatedEvent, task: AnyTaskType) => void;
  onBusy: () => void;
} & WithTranslation &
  WithIdentity;

class TaskViewComponent extends Component<TaskViewProps, TaskViewState> {
  public state: TaskViewState = {
    taskIsFinished: false,
    showConfirmTerminationModal: false,
  };

  private handleTaskSuspendedBound = this.handleTaskSuspended.bind(this);
  private handleTaskFinishedBound = this.handleTaskFinished.bind(this);
  private handleOnProcessInstanceTerminateBound = this.handleOnProcessInstanceTerminate.bind(this);
  private handleOnProcessInstanceTerminatedBound = this.handleOnProcessInstanceTerminated.bind(this);

  private uicEventListenerMap = new Map<string, (event: any) => void>([
    [ManualTaskSuspendedEvent.type, this.handleTaskSuspendedBound],
    [UserTaskSuspendedEvent.type, this.handleTaskSuspendedBound],
    [ManualTaskFinishedEvent.type, this.handleTaskFinishedBound],
    [UserTaskFinishedEvent.type, this.handleTaskFinishedBound],
    [ManualTaskAbortedEvent.type, this.handleTaskSuspendedBound],
    [UserTaskAbortedEvent.type, this.handleTaskSuspendedBound],
    [ProcessInstanceTerminateEvent.type, this.handleOnProcessInstanceTerminateBound],
    [ProcessInstanceTerminatedEvent.type, this.handleOnProcessInstanceTerminatedBound],
    [CustomFormTranscendentErrorEvent.type, this.handleCustomFormTranscendentError],
    ['busy', this.props.onBusy],
  ]);

  private userInteractionComponent = createRef<UserInteractionComponent>();
  private resolveConfirmTerminationModal: (result: 'confirm' | 'cancel') => void = () => void 0;

  public async componentDidMount(): Promise<void> {
    if (this.props.task?.flowNodeType === BpmnType.userTask) {
      await this.props.engineService.reserveUserTask(this.props.task?.flowNodeInstanceId);
    }

    this.handleUserInteractionComponentMount();
  }

  public async componentDidUpdate(prevProps: TaskViewProps, prevState: TaskViewState): Promise<void> {
    if (this.props.task?.flowNodeInstanceId !== prevProps.task?.flowNodeInstanceId) {
      if (prevProps.task?.flowNodeType === BpmnType.userTask && !prevState.taskIsFinished) {
        await this.props.engineService.cancelUserTaskReservation(prevProps.task.flowNodeInstanceId);
      }
      if (this.props.task?.flowNodeType === BpmnType.userTask) {
        await this.props.engineService.reserveUserTask(this.props.task.flowNodeInstanceId);
      }

      if (this.props.task == null) {
        this.handleUserInteractionComponentUnmount();
      } else {
        await this.handleUserInteractionComponentMount();
      }
    }
    if (this.props.identity?.token !== prevProps.identity?.token) {
      this.handleIdentityChanged(this.props.identity);
    }
  }

  public async componentWillUnmount(): Promise<void> {
    if (this.props.task && this.props.task.flowNodeType === BpmnType.userTask && !this.state.taskIsFinished) {
      await this.props.engineService.cancelUserTaskReservation(this.props.task.flowNodeInstanceId);
    }

    this.handleUserInteractionComponentUnmount();
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.state.error && <ErrorRenderer error={this.state.error} />}
        <Modal
          show={this.state.showConfirmTerminationModal}
          onHide={(): void => this.resolveConfirmTerminationModal('cancel')}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.t('TerminationModal.Title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Trans
              i18nKey="TerminationModal.Body"
              components={{
                br: <br />,
                b: <b />,
              }}
            ></Trans>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={(): void => this.resolveConfirmTerminationModal('cancel')}>
              {this.props.t('TerminationModal.CancelButton')}
            </Button>
            <Button variant="danger" onClick={(): void => this.resolveConfirmTerminationModal('confirm')}>
              {this.props.t('TerminationModal.ConfirmButton')}
            </Button>
          </Modal.Footer>
        </Modal>
        <user-interaction-component ref={this.userInteractionComponent} style={{ height: '100%' }} />
      </Fragment>
    );
  }

  private async handleUserInteractionComponentMount(): Promise<void> {
    const { task } = this.props;
    const { identity } = this.props;
    const userInteractionComponent = this.userInteractionComponent.current;

    if (!task || !identity || !task?.flowNodeInstanceId || !userInteractionComponent) {
      return;
    }

    this.handleUserInteractionComponentUnmount();
    userInteractionComponent.identity = identity;
    userInteractionComponent.customFormConfig = {};
    userInteractionComponent.atlasEngineBaseUrl = this.props.engineService.getEngineBaseUrl();
    userInteractionComponent.disableAutoWait = true;
    userInteractionComponent.customFormResolver = this.props.taskViewConfig.resolveCustomForm;
    userInteractionComponent.formStatePersistentService = this.props.componentStatePersistenceService;
    userInteractionComponent.showTerminateButtonIfUserHasClaim = true;
    userInteractionComponent.languageService = this.props.languageService;

    this.uicEventListenerMap.forEach((listener, type) => userInteractionComponent.addEventListener(type, listener));

    if (task.flowNodeType === BpmnType.userTask) {
      await userInteractionComponent.displayUserTask(task.flowNodeInstanceId);
    } else if (task.flowNodeType === BpmnType.manualTask) {
      await userInteractionComponent.displayManualTask(task.flowNodeInstanceId);
    } else {
      throw new Error('Unknown task type.');
    }

    this.props.onReady();
  }

  private handleUserInteractionComponentUnmount(): void {
    const userInteractionComponent = this.userInteractionComponent.current;
    if (!userInteractionComponent) {
      return;
    }

    this.uicEventListenerMap.forEach((listener, type) => userInteractionComponent.removeEventListener(type, listener));
  }

  private handleIdentityChanged(newIdentity: IdentityWithEmail | null): void {
    const userInteractionComponent = this.userInteractionComponent.current;

    if (userInteractionComponent != null) {
      userInteractionComponent.identity = newIdentity;
    }
  }

  private async handleOnProcessInstanceTerminate(event: ProcessInstanceTerminateEvent): Promise<void> {
    event.preventDefault();

    this.setState({ showConfirmTerminationModal: true });

    const result = await new Promise((resolve: (result: 'confirm' | 'cancel') => void) => {
      this.resolveConfirmTerminationModal = resolve;
    });

    this.setState({ showConfirmTerminationModal: false });

    if (result === 'cancel') {
      event.abort = true;
      return;
    }

    if (this.props.task != null) {
      try {
        this.props.onBusy();
        await this.props.engineService.terminateProcessInstance(this.props.task.processInstanceId);
      } catch (error) {
        event.error = error;
        this.setState({ error: error });
      }
    }
  }

  private async handleOnProcessInstanceTerminated(event: ProcessInstanceTerminatedEvent): Promise<void> {
    this.setState({
      taskIsFinished: true,
    });

    this.props.onProcessInstanceTerminated(event, this.props.task!);
  }

  private handleTaskFinished(event: ManualTaskFinishedEvent | UserTaskFinishedEvent): void {
    this.setState({
      taskIsFinished: true,
    });

    this.props.onTaskFinished(event, this.props.task!);
  }

  private handleTaskSuspended(
    event: ManualTaskSuspendedEvent | UserTaskSuspendedEvent | ManualTaskAbortedEvent | UserTaskAbortedEvent
  ): void {
    this.props.onTaskSuspended(event, this.props.task!);
  }

  private handleCustomFormTranscendentError(event: CustomFormTranscendentErrorEvent): void {
    window.dispatchEvent(event.errorOrUnhandledRejectionEvent);
  }
}

export const TaskView = withTranslation()(withIdentity(TaskViewComponent));
