import { LinkContainer } from 'react-router-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

import { AnyTaskType } from '../../../lib/index';

type TaskProps = {
  task: AnyTaskType;
  processInstancePath: string;
};

export function Task(props: TaskProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="task">
      <span className="task__title">
        <Trans
          i18nKey="TaskList.TasksForCorrelationName"
          values={{ correlationName: props.processInstancePath }}
          components={{ bold: <b /> }}
        />
      </span>
      <span className="task__task">
        <LinkContainer
          to={`/task/${props.task.correlationId}/${props.task.processInstanceId}/${props.task.flowNodeInstanceId}`}
        >
          <button className="btn btn-sm btn--task-continue">
            {t('CorrelationTracker.Continue', { taskName: props.task.flowNodeName ?? props.processInstancePath })}
          </button>
        </LinkContainer>
      </span>
    </div>
  );
}
