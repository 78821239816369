import { IAuthService, IdentityWithEmail, OnIdentityChangedCallback } from './IAuthService';
import ProductInfo from '../generatedProductInfo';

export class LogEmitter {
  protected identity: IdentityWithEmail | null = null;

  private authService: IAuthService | null = null;

  constructor(authServicePromise: Promise<IAuthService>) {
    authServicePromise.then((authService) => {
      this.authService = authService;

      const identityChangedCallback: OnIdentityChangedCallback = (newIdentity) => {
        this.identity = newIdentity;
      };

      this.authService.onIdentityChanged(identityChangedCallback);
    });
  }

  public emitErrorLog(error: Error, additionalInformation: Record<string, any> = {}): void {
    const additionalErrorProperties = { ...error };

    const body = {
      error: {
        ...additionalErrorProperties,
        name: error.name,
        message: error.message,
        stack: error.stack,
      },
      ...additionalInformation,
      userEmail: this.identity?.email,
      userId: this.identity?.userId,
      anonymousSessionId: this.identity?.anonymousSessionId,
      timeStamp: new Date().getTime() / 1000,
      portalVersion: ProductInfo.version.frontend,
      url: window.location.href,
    };

    fetch(`${process.env.PUBLIC_URL}/logevent`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.identity?.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }
}
