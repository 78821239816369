import { ComponentType } from 'react';

import { PortalConfiguration } from '@atlas-engine-contrib/atlas-ui_contracts';
import type { LanguageService } from './LanguageService';

export class Portal {
  public config: PortalConfiguration;

  private renderHooks: { [id: string]: Array<ComponentType> } = {};

  private languageService: LanguageService;

  constructor(config: PortalConfiguration, languageService: LanguageService) {
    this.config = config;
    this.languageService = languageService;
  }

  public registerRenderHook(id: string, reactComponent: ComponentType): void {
    if (this.renderHooks[id] == null) {
      this.renderHooks[id] = [];
    }

    this.renderHooks[id].push(reactComponent);
  }

  public getRenderHooks(id: string): Array<ComponentType> | null {
    return this.renderHooks[id] || null;
  }

  public getCurrentLanguage(): ReturnType<LanguageService['getCurrentLanguage']> {
    return this.languageService.getCurrentLanguage();
  }

  public getLanguages(): ReturnType<LanguageService['getLanguages']> {
    return this.languageService.getLanguages();
  }

  public setLanguage(
    ...params: Parameters<LanguageService['setLanguage']>
  ): ReturnType<LanguageService['setLanguage']> {
    return this.languageService.setLanguage(...params);
  }

  public onLanguageChanged(
    ...params: Parameters<LanguageService['onLanguageChanged']>
  ): ReturnType<LanguageService['onLanguageChanged']> {
    return this.languageService.onLanguageChanged(...params);
  }

  public removeOnLanguageChanged(
    ...params: Parameters<LanguageService['removeOnLanguageChanged']>
  ): ReturnType<LanguageService['removeOnLanguageChanged']> {
    return this.languageService.removeOnLanguageChanged(...params);
  }
}
