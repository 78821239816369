import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import { IAuthService } from '../../../lib';
import { Layout, LayoutContent, LayoutHeader, LayoutSidebar } from '../../Layout';
import { GenericViewProps } from '../../GenericViewProps';
import ProductInfo from '../../../generatedProductInfo';
import type { LanguageService } from '../../../lib/LanguageService';
import { useEffect, useState } from 'react';

export type UserSettingsViewProps = {
  authService?: IAuthService;
  languageService?: LanguageService;
} & RouteComponentProps &
  WithTranslation &
  GenericViewProps;

function UserSettingsView(props: UserSettingsViewProps): JSX.Element {
  const { t } = props;

  const currentLanguage = props.languageService?.getCurrentLanguage();
  const languages = props.languageService?.getLanguages();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(props.authService?.isLoggedIn() === true);

    const onIdentityChangedCallback = (): void => {
      setIsLoggedIn(props.authService?.isLoggedIn() === true);
    };

    props.authService?.onIdentityChanged(onIdentityChangedCallback);

    return (): void => {
      props.authService?.removeOnIdentityChanged(onIdentityChangedCallback);
    };
  }, [props.authService]);

  return (
    <Layout>
      <LayoutSidebar
        activeNav="user-settings"
        visible={props.sidebarVisible}
        hideSidebar={props.hideSidebar}
        logo={props.logo}
      />
      <LayoutHeader title={t('Header.TitleUserSettings')} showSearch={false} onMenuClick={props.onMenuClick} />
      <LayoutContent>
        <div className="user-settings-view">
          <div className="user-settings-view__content">
            <div className="box">
              <div className="box__title">{props.t('UserSettings.HeaderLanguages')}</div>
              <div className="box__body">
                <div className="form-group">
                  <select
                    className="form-control custom-select custom-select-sm user-settings-view__language"
                    value={currentLanguage}
                    onChange={(event): Promise<void> | undefined =>
                      props.languageService?.setLanguage(event.target.value)
                    }
                  >
                    {languages &&
                      Object.entries(languages).map((languageEntry, index) => {
                        const value = languageEntry[0];
                        const displayName = languageEntry[1];

                        return (
                          <option key={`${value}_${displayName}_${index}`} value={value}>
                            {displayName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>

            {isLoggedIn && (
              <div className="box">
                <div className="box__title">{props.t('UserSettings.HeaderLogout')}</div>
                <div className="box__body">
                  <button
                    className="btn btn-outline-danger btn--logout"
                    onClick={(): void => props.authService?.logout()}
                  >
                    {props.t('UserSettings.Logout')}
                  </button>
                </div>
              </div>
            )}

            <div className="user-settings-view__version">
              Portal Version {ProductInfo.version.frontend} (packaged with Backend Version {ProductInfo.version.backend}
              )
            </div>
          </div>
        </div>
      </LayoutContent>
    </Layout>
  );
}

export const UserSettingsViewWithRouter = withTranslation()(withRouter(UserSettingsView));
