import { ClientFactory } from '@atlas-engine/atlas_engine_client';
import {
  CustomFormConfig,
  CustomFormEntry,
  CustomFormResolver,
  PortalConfiguration,
} from '@atlas-engine-contrib/atlas-ui_contracts';

type CustomFormConfigEntry = CustomFormEntry & {
  raw: string;
  test: (str: string) => boolean;
};

type CustomFormTester = (str: string) => boolean;

const defaultConfig = {
  applicationBaseUrl: 'http://localhost:13590',
  engineUrl: 'http://localhost:10560',
  useAuthority: false,
  authorityConfiguration: {
    clientId: '',
    scopes: '',
    responseType: 'id_token token',
    grantType: 'implicit',
  },
  processModels: {
    include: [],
    exclude: [],
    settings: {},
  },
  favicon: '/assets/favicon.png',
  logo: '/assets/logo.png',
  languages: {
    de: 'Deutsch',
    en: 'English',
  },
};

export class ConfigurationError extends Error {}

export class ConfigurationService {
  public async loadConfig(): Promise<PortalConfiguration> {
    let fetchedConfig;

    try {
      const response = await fetch(`${process.env.PUBLIC_URL}/config/config.json`);

      fetchedConfig = await response.json();
    } catch (error) {
      const isDevEnv = process.env.NODE_ENV?.toLowerCase() === 'development';
      if (isDevEnv) {
        console.warn('Failed to load config from server!');
        console.warn('Using a default config, because the current NODE_ENV is "development".');
        fetchedConfig = defaultConfig;
      } else {
        throw new ConfigurationError('Unable to retrieve configuration from the 5Minds Portal server!');
      }
    }

    const appConfig = await this.createAppConfig(fetchedConfig);
    return appConfig;
  }

  private async createAppConfig(fetchedConfig: any): Promise<PortalConfiguration> {
    const applicationBaseUrl = fetchedConfig?.applicationBaseUrl || defaultConfig.applicationBaseUrl;

    const engineUrl = fetchedConfig?.engineUrl || defaultConfig.engineUrl;
    const authorityUrl = await this.getEngineAuthorityUrl(engineUrl);

    const appConfig: PortalConfiguration = {
      applicationBaseUrl: applicationBaseUrl,
      engineUrl: engineUrl,
      // Accounts for parameters read from the .json file and parameters read from the console
      useAuthority: fetchedConfig?.useAuthority === true || fetchedConfig?.useAuthority === 'true',
      authorityConfiguration: {
        ...fetchedConfig?.authorityConfiguration,
        redirectBasePath: applicationBaseUrl,
      },
      useStartDialogAsHomepage: fetchedConfig?.useStartDialogAsHomepage,
      customForms: fetchedConfig?.customForms ?? {},
      processModels: fetchedConfig?.processModels ?? defaultConfig.processModels,
      startDialogs: fetchedConfig.startDialogs ?? {},
      taskViewConfig: {
        resolveCustomForm: this.convertCustomFormObjectToResolver(fetchedConfig?.customForms ?? {}),
      },
      startablesOrder: fetchedConfig?.startablesOrder,
      startableGroups: fetchedConfig?.startableGroups,
      favicon: fetchedConfig?.favicon,
      logo: fetchedConfig?.logo,
      languages: fetchedConfig?.languages ?? defaultConfig.languages,
    };

    if (authorityUrl != null) {
      appConfig.authorityConfiguration.authority = authorityUrl;
    }

    return appConfig;
  }

  private async getEngineAuthorityUrl(engineUrl: string): Promise<string | null> {
    try {
      const engineAppInfoClient = ClientFactory.createApplicationInfoClient(engineUrl);

      const authorityAddress = await engineAppInfoClient.getAuthorityAddress();

      engineAppInfoClient.dispose();

      return authorityAddress;
    } catch (error) {
      return null;
    }
  }

  /**
   * Converts the given CustomForm configuration to a resolver function.
   * The function will attempt to resolve the given CustomForm ID to a CustomFormEntry.
   */
  private convertCustomFormObjectToResolver(customFormConfig: CustomFormConfig): CustomFormResolver {
    const customForms = Object.entries(customFormConfig).map(([raw, customFormEntry]) =>
      this.mapCustomFormConfigEntry(raw, customFormEntry)
    );

    return (customFormId: string | undefined): CustomFormEntry | null => {
      if (!customFormId) {
        return null;
      }

      const customForm = customForms.find((e) => e.test(customFormId));

      return customForm ?? null;
    };
  }

  private mapCustomFormConfigEntry(raw: string, customFormEntryOrUrl: string | CustomFormEntry): CustomFormConfigEntry {
    const customFormEntry = this.parseCustomFormEntry(customFormEntryOrUrl);

    const rawStringIsRegEx = raw.startsWith('/') && raw.endsWith('/');
    if (rawStringIsRegEx) {
      try {
        const regex = RegExp(raw.slice(1, -1));

        const customFormTester = (str: string): boolean => str.match(regex) != null;

        const customFormConfigEntry = this.buildCustomFormConfigEntry(raw, customFormEntry, customFormTester);

        return customFormConfigEntry;
      } catch (error) {
        console.warn('Unable to parse regex for custom form: ', raw, error);
      }
    }

    const customFormConfigEntry = this.buildCustomFormConfigEntry(raw, customFormEntry);

    return customFormConfigEntry;
  }

  private parseCustomFormEntry(customFormEntryOrUrlString: string | CustomFormEntry): CustomFormEntry {
    return typeof customFormEntryOrUrlString === 'string'
      ? this.getDefaultCustomFormEntryForUrl(customFormEntryOrUrlString)
      : customFormEntryOrUrlString;
  }

  private getDefaultCustomFormEntryForUrl(url: string): CustomFormEntry {
    return {
      mode: 'default' as CustomFormEntry['mode'],
      url: url,
    };
  }

  private buildCustomFormConfigEntry(
    raw: string,
    customFormEntry: CustomFormEntry,
    customformTester: CustomFormTester = (str: string): boolean => raw === str
  ): CustomFormConfigEntry {
    return {
      raw: raw,
      test: customformTester,
      url: customFormEntry.url,
      mode: customFormEntry.mode,
    };
  }
}
