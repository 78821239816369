import { useContext } from 'react';
import * as router from 'react-router-dom';
import { TranslatedCorrelationTrackerView } from './CorrelationTrackerView';

import { EngineContext } from '../../../lib/index';
import { GenericViewProps } from '../../GenericViewProps';

type CorrelationTrackerViewParameters = {
  correlationId: string;
};

export function CorrelationTrackerViewWithRouter(genericViewProps: GenericViewProps): JSX.Element {
  const engineService = useContext(EngineContext);
  const { correlationId } = router.useParams<CorrelationTrackerViewParameters>();
  const { search, state } = router.useLocation();

  if (!engineService) {
    throw new Error('Atlas Engine Service missing in app context');
  }

  if (!correlationId) {
    return <span>Error</span>;
  }

  const params = new URLSearchParams(search);
  const autoNavigate = params.get('autoNavigate') !== 'false';
  const processInstanceId = (state as any)?.processInstanceId as string | undefined;

  return (
    <TranslatedCorrelationTrackerView
      {...genericViewProps}
      correlationId={correlationId}
      autoNavigate={autoNavigate}
      engineService={engineService}
      processInstanceId={processInstanceId}
      loadingSpinnerActive={(state as any)?.loadingSpinnerActive === true}
    />
  );
}
