import { StartDialogsConfig } from '@atlas-engine-contrib/atlas-ui_contracts';

import { IAuthService } from './IAuthService';

export async function getAllowedStartDialogs(
  startDialogsConfig: StartDialogsConfig,
  authService: IAuthService
): Promise<StartDialogsConfig> {
  const allowedStartDialogs: StartDialogsConfig = {};

  for await (const [startDialogId, startDialogConfig] of Object.entries(startDialogsConfig)) {
    const requiredClaims = startDialogConfig.requiredClaims;

    if (!requiredClaims || requiredClaims.length === 0) {
      allowedStartDialogs[startDialogId] = startDialogConfig;
      continue;
    }

    let userHasClaim = false;

    for await (const claim of requiredClaims) {
      if (await authService.hasClaim(claim)) {
        userHasClaim = true;
        break;
      }
    }

    if (userHasClaim) {
      allowedStartDialogs[startDialogId] = startDialogConfig;
    }
  }

  return allowedStartDialogs;
}
