import type { OnLanguageChangedCallback, PortalConfiguration } from '@atlas-engine-contrib/atlas-ui_contracts';
import i18n from '../i18n';

export class LanguageService {
  private readonly config: PortalConfiguration;
  private onLanguageChangedCallbacks = new Set<OnLanguageChangedCallback>();

  constructor(portalConfig: PortalConfiguration) {
    this.config = portalConfig;
  }

  public getCurrentLanguage(): string {
    const i18nLanguage = i18n.language.replace(/-.+$/, '');

    let currentLanguageCode = i18n.options.fallbackLng?.toString() as string;
    const languages = this.getLanguages();

    if (languages) {
      const languageCountryCodes = Object.keys(languages);
      const languageIsUsable = languageCountryCodes.some((lang) => lang === i18nLanguage);
      if (languageIsUsable) {
        currentLanguageCode = i18nLanguage;
      } else {
        if (!languageCountryCodes.some((lang) => lang === currentLanguageCode) && languageCountryCodes.length > 0) {
          currentLanguageCode = languageCountryCodes[0];
        }
        i18n.changeLanguage(currentLanguageCode);
      }
    }

    return currentLanguageCode;
  }

  public getLanguages(): PortalConfiguration['languages'] {
    return this.config.languages;
  }

  public setLanguage(countryCode: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const languages = this.getLanguages();

      if (languages && languages[countryCode] == null) {
        return reject(
          new Error(
            `The requested language '${countryCode}' is not configured.\n\nAvailable languages ​​are:\n${Object.keys(
              languages
            ).join('\n')}`
          )
        );
      }

      i18n.changeLanguage(countryCode, (error) => {
        if (error) {
          return reject(error);
        }

        for (let callback of this.onLanguageChangedCallbacks) {
          callback(countryCode);
        }
        resolve();
      });
    });
  }

  public onLanguageChanged(callback: OnLanguageChangedCallback): void {
    this.onLanguageChangedCallbacks.add(callback);
  }

  public removeOnLanguageChanged(callback: OnLanguageChangedCallback): void {
    this.onLanguageChangedCallbacks.delete(callback);
  }
}
