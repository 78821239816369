import { Layout } from './Layout';

// This component intentionally doesn't use the "AppSidebar" and "AppHeader" components because they use translations.
// It is designed to be a placeholder while loading the translations which doesn't work when the placeholder requires
// translations (duh!).
export function AppSkeleton(): JSX.Element {
  return (
    <Layout>
      <div className="app-layout__app-sidebar">
        <div className="app-sidebar app-sidebar--visible"></div>
      </div>
      <div className="app-layout__app-header">
        <div className="app-header"></div>
      </div>
    </Layout>
  );
}
