import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';

import { Subscription } from '@atlas-engine/atlas_engine_sdk';
import { ProcessModelConfig, StartDialogsConfig, StartableGroupConfig } from '@atlas-engine-contrib/atlas-ui_contracts';

import { EngineService, IAuthService, getAllowedStartDialogs } from '../../../lib';
import { DelayedRenderer } from '../../components/DelayedRenderer';
import { ErrorRenderer } from '../../components/ErrorRenderer';
import { StartableList } from '../../components/startable-list/StartableList';
import { Layout, LayoutContent, LayoutHeader, LayoutSidebar } from '../../Layout';
import { GenericViewProps } from '../../GenericViewProps';

export type StartableListViewProps = {
  engineService: EngineService;
  authService: IAuthService;
  startDialogsConfig: StartDialogsConfig;
  startablesOrder?: Array<string>;
  startableGroups?: Array<StartableGroupConfig>;
} & RouteComponentProps &
  WithTranslation &
  GenericViewProps;

export type StartableListViewState = {
  searchFilter: string;
  processModels?: Array<ProcessModelConfig>;
  startDialogs?: StartDialogsConfig;
  loadingError?: Error;
};

class StartableListView extends Component<StartableListViewProps, StartableListViewState> {
  public state: StartableListViewState = {
    searchFilter: '',
  };

  private deployedProcessesChangedSubscriptions: Array<Subscription> = [];

  public async componentDidMount(): Promise<void> {
    try {
      await this.loadProcessModels();
      await this.loadStartDialogs();

      this.deployedProcessesChangedSubscriptions = await this.props.engineService.onDeployedProcessesChanged(
        this.loadProcessModels.bind(this)
      );
    } catch (error) {
      this.setState({ loadingError: error });
    }
  }

  public async componentWillUnmount(): Promise<void> {
    await this.props.engineService.removeSubscriptions(this.deployedProcessesChangedSubscriptions);
  }

  public render(): JSX.Element {
    const { t } = this.props;

    const loadingError = this.state.loadingError ? <ErrorRenderer error={this.state.loadingError} /> : null;

    const startableList =
      this.state.processModels && this.state.startDialogs ? (
        <StartableList
          processModels={this.state.processModels}
          startDialogs={this.state.startDialogs}
          startablesOrder={this.props.startablesOrder}
          startableGroups={this.props.startableGroups}
          engineService={this.props.engineService}
          searchFilter={this.state.searchFilter}
        />
      ) : (
        <DelayedRenderer>
          <Alert variant="info">{this.props.t('StartableList.DataLoading')}</Alert>
        </DelayedRenderer>
      );

    const onSearchChanged = (value: string): void => {
      this.setState({ searchFilter: value });
    };

    return (
      <Layout>
        <LayoutSidebar
          activeNav="startable-list"
          visible={this.props.sidebarVisible}
          hideSidebar={this.props.hideSidebar}
          logo={this.props.logo}
        />
        <LayoutHeader
          title={t('Header.TitleStartableList')}
          showSearch={true}
          onSearchChanged={onSearchChanged}
          onMenuClick={this.props.onMenuClick}
        />
        <LayoutContent>
          {loadingError ? loadingError : <div className="startable-list-view">{startableList}</div>}
        </LayoutContent>
      </Layout>
    );
  }

  private async loadProcessModels(): Promise<void> {
    const processModels = await this.props.engineService.getProcessModels();

    this.setState({
      processModels: processModels,
    });
  }

  private async loadStartDialogs(): Promise<void> {
    const { startDialogsConfig, authService } = this.props;
    const startDialogs = await getAllowedStartDialogs(startDialogsConfig, authService);

    this.setState({
      startDialogs: startDialogs,
    });
  }
}

export const StartableListViewWithRouter = withTranslation()(withRouter(StartableListView));
