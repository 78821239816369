import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Base64 } from 'js-base64';

import { EngineService } from '../../../lib/index';
import { ErrorRenderer } from '../../components/ErrorRenderer';
import { Layout, LayoutContent, LayoutSidebar } from '../../Layout';
import { BackToHomepage } from '../../components/BackToHomepage';
import { GenericViewProps } from '../../GenericViewProps';
import { DelayedRenderer } from '../../components/DelayedRenderer';
import LoadingSpinner from '../../components/LoadingSpinner';

export type StartProcessViaUrlViewProps = GenericViewProps & {
  engineService: EngineService;
};

type StartProcessViaUrlParameters = {
  processModelId: string;
  payload: any;
};

export function StartProcessViaUrlView(props: StartProcessViaUrlViewProps): JSX.Element {
  const { t } = useTranslation();

  const { processModelId, payload: encodedPayload } = useParams<StartProcessViaUrlParameters>();

  const queryParams = useQuery();
  const startEventId = queryParams.get('startEventId') ?? undefined;
  const correlationId = queryParams.get('correlationId') ?? undefined;

  const history = useHistory();
  const [lastError, setLastError] = useState<Error | null>(null);
  const loadingSpinnerRef = useRef();

  useEffect(() => {
    if (!processModelId) {
      return;
    }

    let payload;
    if (encodedPayload) {
      try {
        payload = JSON.parse(Base64.decode(encodedPayload));
      } catch (error) {
        setLastError(error);
        return;
      }
    }
    props.engineService
      .startProcessInstance(processModelId, payload, startEventId, correlationId)
      .then((result) => {
        history.replace(`/correlation/${result.correlationId}`, {
          loadingSpinnerActive: loadingSpinnerRef.current != null,
          processInstanceId: result.processInstanceId,
        });
      })
      .catch((error) => {
        setLastError(error);
      });
  }, [processModelId, encodedPayload, props.engineService, history]);

  return (
    <Layout>
      <LayoutSidebar visible={props.sidebarVisible} hideSidebar={props.hideSidebar} logo={props.logo} />
      {!lastError && (
        <DelayedRenderer>
          <LoadingSpinner htmlRef={loadingSpinnerRef} style={{ gridArea: 'content' }} />
        </DelayedRenderer>
      )}
      <LayoutContent>
        <BackToHomepage />
        {lastError && <ErrorRenderer error={lastError} />}
      </LayoutContent>
    </Layout>
  );
}

function useQuery(): URLSearchParams {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
