import { TaskListWithCorrelation, matchesSearchFilter } from '../../../lib/index';

export function filterTaskListsWithCorrelation(
  taskGroups: Array<TaskListWithCorrelation>,
  searchFilter: string
): Array<TaskListWithCorrelation> {
  return taskGroups.filter((taskGroup) => matches(taskGroup, searchFilter));
}

function matches(taskListWithCorrelation: TaskListWithCorrelation, searchFilter: string): boolean {
  const correlationName = taskListWithCorrelation.correlationName;
  const correlationDescription = taskListWithCorrelation.correlationDescription;
  const flowNodeNames = taskListWithCorrelation.taskList.map((task) => task.flowNodeName).join(' ');
  const taskListWithCorrelationAsSearchableString = `${correlationName} ${correlationDescription} ${flowNodeNames}`;

  return matchesSearchFilter(taskListWithCorrelationAsSearchableString, searchFilter);
}
