export default function LoadingSpinner(props: {
  htmlRef?: React.LegacyRef<any>;
  style?: React.CSSProperties;
  sublabel?: string | JSX.Element;
  backdrop?: boolean;
}): JSX.Element {
  return (
    <>
      {props.backdrop && <span className="loading-spinner__backdrop" style={props.style}></span>}
      <span ref={props.htmlRef} className="loading-spinner" style={props.style}>
        {props.sublabel && <p className="loading-spinner__sublabel">{props.sublabel}</p>}
      </span>
    </>
  );
}
