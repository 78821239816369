import { FormState, IFormStatePersistenceService } from '@atlas-engine-contrib/atlas-ui_contracts';

import { IAuthService } from './IAuthService';

export class ComponentStateAPIError extends Error {}

export class ComponentStatePersistenceService implements IFormStatePersistenceService {
  private authService: IAuthService;

  constructor(authService: IAuthService) {
    this.authService = authService;
  }

  public load = async (flowNodeInstanceId: string): Promise<FormState> => {
    let fetchedState;
    const identity = await this.authService.getIdentity();
    const userId =
      this.authService.getGrantType() === 'client_credentials' ? identity.anonymousSessionId : identity.userId;
    const url = `${process.env.PUBLIC_URL}/api/state/${userId}/${flowNodeInstanceId}`;

    try {
      const response = await fetch(url);
      fetchedState = await response.json();
    } catch (error) {
      const isDevEnv = process.env.NODE_ENV === 'development';
      if (isDevEnv) {
        console.warn('Failed to load Component state from server!');
        console.warn('Loading state from local storage, because the current NODE_ENV is "development".');
        fetchedState = await this.loadFromLocalStorage(flowNodeInstanceId);
      } else {
        throw new ComponentStateAPIError('Unable to load state! The server is unreachable!');
      }
    }

    return fetchedState;
  };

  public persist = async (flowNodeInstanceId: string, state: FormState): Promise<void> => {
    const identity = await this.authService.getIdentity();
    const userId =
      this.authService.getGrantType() === 'client_credentials' ? identity.anonymousSessionId : identity.userId;
    const url = `${process.env.PUBLIC_URL}/api/state/${userId}/${flowNodeInstanceId}`;

    try {
      await fetch(url, {
        method: 'POST',
        body: JSON.stringify(state),
        headers: new Headers({ 'content-type': 'application/json' }),
      });
    } catch (error) {
      const isDevEnv = process.env.NODE_ENV === 'development';
      if (isDevEnv) {
        console.warn('Failed to send Component state to server!');
        console.warn('Saving state to local storage, because the current NODE_ENV is "development".');
        await this.persistToLocalStorage(flowNodeInstanceId, state);
      } else {
        throw new ComponentStateAPIError('Unable to save state! The server is unreachable!');
      }
    }
  };

  private async loadFromLocalStorage(flowNodeInstanceId: string): Promise<FormState> {
    const state = JSON.parse(window.localStorage.getItem(flowNodeInstanceId) as string);
    return state;
  }

  private async persistToLocalStorage(flowNodeInstanceId: string, state: FormState): Promise<void> {
    window.localStorage.setItem(flowNodeInstanceId, JSON.stringify(state));
  }
}
