import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';

import { DataModels, Subscription } from '@atlas-engine/atlas_engine_sdk';
import { ProcessModelConfig, StartDialogsConfig, StartableGroupConfig } from '@atlas-engine-contrib/atlas-ui_contracts';

import { EngineService, IAuthService, getAllowedStartDialogs } from '../../../lib';
import { DelayedRenderer } from '../../components/DelayedRenderer';
import { ErrorRenderer } from '../../components/ErrorRenderer';
import { FlashMessage } from '../../components/FlashMessage';
import { StartableList } from '../../components/startable-list/StartableList';
import { TranslatedTaskList } from '../../components/task-list/TaskList';
import { Layout, LayoutContent, LayoutHeader, LayoutSidebar } from '../../Layout';
import { GenericViewProps } from '../../GenericViewProps';

export type HomepageViewProps = {
  engineService: EngineService;
  authService: IAuthService;
  startDialogsConfig: StartDialogsConfig;
  startablesOrder?: Array<string>;
  startableGroups?: Array<StartableGroupConfig>;
} & RouteComponentProps &
  WithTranslation &
  GenericViewProps;

export type HomepageViewState = {
  correlationIsFinished: boolean;
  searchFilter: string;
  processModels?: Array<ProcessModelConfig>;
  startDialogs?: StartDialogsConfig;
  loadingError?: Error;
};

class HomepageView extends Component<HomepageViewProps, HomepageViewState> {
  public correlationId: string | null = new URLSearchParams(this.props.location.search).get('lastActiveCorrelation');

  public state: HomepageViewState = {
    correlationIsFinished: false,
    searchFilter: '',
  };

  private deployedProcessesChangedSubscriptions: Array<Subscription> = [];

  public async componentDidMount(): Promise<void> {
    try {
      if (this.correlationId) {
        const correlation = await this.props.engineService.getCorrelation(this.correlationId);
        const correlationIsFinished =
          correlation.processInstances?.every(
            (e) => e.state === DataModels.ProcessInstances.ProcessInstanceState.finished
          ) ?? true;

        this.setState({ correlationIsFinished: correlationIsFinished });
      }

      await this.loadProcessModels();
      await this.loadStartDialogs();

      this.deployedProcessesChangedSubscriptions = await this.props.engineService.onDeployedProcessesChanged(
        this.loadProcessModels.bind(this)
      );
    } catch (error) {
      this.setState({ loadingError: error });
    }
  }

  public async componentWillUnmount(): Promise<void> {
    await this.props.engineService.removeSubscriptions(this.deployedProcessesChangedSubscriptions);
  }

  public render(): JSX.Element {
    const { t } = this.props;

    const loadingError = this.state.loadingError ? <ErrorRenderer error={this.state.loadingError} /> : null;

    const processCompletedMessage = this.state.correlationIsFinished ? (
      <div className="homepage-view__infopoint">
        <FlashMessage variant="success" text={this.props.t('Homepage.ProcessCompletedSuccessfully')} />
      </div>
    ) : null;

    const startableList =
      this.state.processModels && this.state.startDialogs ? (
        <StartableList
          processModels={this.state.processModels}
          startDialogs={this.state.startDialogs}
          startablesOrder={this.props.startablesOrder}
          startableGroups={this.props.startableGroups}
          engineService={this.props.engineService}
          searchFilter={this.state.searchFilter}
          maxVisibleStartables={8}
          showGroups={false}
        />
      ) : (
        <DelayedRenderer>
          <Alert variant="info">{this.props.t('StartableList.DataLoading')}</Alert>
        </DelayedRenderer>
      );

    const onSearchChanged = (value: string): void => {
      this.setState({ searchFilter: value });
    };

    return (
      <Layout>
        <LayoutSidebar
          activeNav="homepage"
          visible={this.props.sidebarVisible}
          hideSidebar={this.props.hideSidebar}
          logo={this.props.logo}
        />
        <LayoutHeader
          title={t('Header.TitleHomepage')}
          showSearch={true}
          onSearchChanged={onSearchChanged}
          onMenuClick={this.props.onMenuClick}
        />
        <LayoutContent>
          <div className="homepage-view">
            {loadingError}
            {processCompletedMessage}
            <div className="homepage-view__startable-list">{loadingError ? null : startableList}</div>
            <div className="homepage-view__task-list">
              <TranslatedTaskList engineService={this.props.engineService} searchFilter={this.state.searchFilter} />
            </div>
          </div>
        </LayoutContent>
      </Layout>
    );
  }

  private async loadProcessModels(): Promise<void> {
    const processModels = await this.props.engineService.getProcessModels();

    this.setState({
      processModels: processModels,
    });
  }

  private async loadStartDialogs(): Promise<void> {
    const { startDialogsConfig, authService } = this.props;
    const startDialogs = await getAllowedStartDialogs(startDialogsConfig, authService);

    this.setState({
      startDialogs: startDialogs,
    });
  }
}

export const HomepageWithRouter = withTranslation()(withRouter(HomepageView));
